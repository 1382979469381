/**
 * = Modals
 */

.modal{
    &.static-example{
        position: relative;
        display: block;
    }
}

.modal-content {
    border: 0;
    @if $enable-rounded {
        border-radius: $modal-content-border-radius;
    } @else {
        border-radius: 0;
    }
}

.modal-fluid {
    .modal-dialog {
    
        margin-top: 0;
        margin-bottom: 0;
    }
    .modal-content {
        border-radius: 0;
    }
}


@media (min-width: 576px){
 .modal-dialog {
    max-width: 900px;
    margin: 1.75rem auto;
}   
}
