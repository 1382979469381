@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap");

// Bootstrap mixins and functions
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/functions";

// Change variables here
@import "idntica/variables";

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

// Vendor
@import "idntica/vendor";

// volt mixins & functions
@import "idntica/mixins";
@import "idntica/functions";

// Utilities
@import "idntica/reboot";
@import "idntica/utilities";

// Layout
@import "idntica/layout";

// Components
@import "idntica/components";

// write your custom styles here!
