.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.navbar-search form {
  color: #555;
  display: flex;
  
  border: 1px solid rgb(233, 230, 230);
  border-radius: 10px;
}
.navbar-search input[type="search"] {
  width: 100%;
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
}

.navbar-search input[type="search"]::placeholder {
  color: #bbb;
  font-size: 14px;
}

.navbar-search .search-mem-form{
  background-color: rgb(172, 169, 169);
  color: white;
  font-size: 12px;
}
.navbar-search input{
  margin-left: 30px;
 
}
.btn-submit{
  background-color: rgb(26, 110, 227);
  border-radius: 8px ;
  border: 2px solid rgb(26, 110, 227);
  color: white;
}
.input-phonecode-s{
  width:600px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 580px) {
  .navbar-search span{
    font-size: 10px;
    
  }
  .navbar-search input{
    margin-left: 0px;
   
  }
.btn-choosefile{
   width: 220px;
  }
  .input-phonecode-s{
    width:100px;
  }
}